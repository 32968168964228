import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { PF } from "../../config";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Badge, Box, Button, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";
import LoadingBox from "../misc/Loading";

export default function Banner() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // use State
  const [bannerLoading, setBannerLoading] = useState(false);
  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");
  const [medList, setMedList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();

  // Store
  const { medicines, categories, companies, dosages } = useSelector(
    (state) => state.medicine
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  // Functions
  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    catList.map((x) => {
      let Sc = x.subCatList.find(
        (sc) =>
          (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
          subCatId
      );
      if (Sc) {
        subCat = Sc.subCatName;
        cat = x.catName;
      }
      return 0;
    });
    return { cat, subCat };
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
    return "";
  };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    const M = medicines.find((m) => m.lang === "en");
    if (M) {
      const med = M.data.find((x) => x.fixedMedicineId === id);
      if (med) genericBrand = med.genericBrand;
    }
    return genericBrand;
  };

  const handleViewMedicine = (x) => {
    navigate(
      `/our-medicines/${makeUrlFriendly(
        getGenericBrand(
          lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
        )
      )}`
    );
  };

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // dispatch(getMedCategories());
    // dispatch(getCompanies());
    // dispatch(getDosages());
  }, []);

  useEffect(() => {
    setBannerLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [...M.data].sort((a, b) =>
          a.genericBrand.toLowerCase() > b.genericBrand.toLowerCase() ? 1 : -1
        );
        setMedList(
          arr.filter((a) => a?.dealOfTheWeek === true && a?.visible === true)
        );
      } else {
        setMedList([]);
      }
    }
    if (medicines) {
      setTimeout(() => {
        setBannerLoading(false);
      }, 1000);
    }
  }, [medicines, lang]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront.langId);
    }
  }, [activeLangFront]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  // Use Effect
  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  // Get Alt Tag
  const getSubCatString = (subCatArr) => {
    let subCatNameArr = [];

    const arr = [...subCatArr];
    arr.forEach((a) => {
      catList.forEach((x) => {
        let Sc = x.subCatList.find(
          (sc) =>
            (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
            a.subCatId
        );
        if (Sc) {
          subCatNameArr.push(Sc.subCatName);
        }
      });
    });

    return subCatNameArr.join(", ") || "";
  };

  return (
    <>
      {bannerLoading ? (
        <LoadingBox w={"100%"} h={316} />
      ) : (
        medList.length > 0 && (
          <Swiper
            slidesPerView={xlUp ? 3 : mdUp ? 2 : 1}
            grabCursor
            modules={[Navigation, Scrollbar, Pagination, Autoplay]}
            autoplay={{ delay: 5000 }}
            loop={{ loop: true }}
            spaceBetween={10}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            className="deal"
          >
            {medList.map((x, i) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    border: `2px solid ${
                      webSettings.siteColorTheme || "lightblue"
                    }`,
                    borderRadius: ".25rem",
                    my: 1,
                  }}
                >
                  {/* <Card sx={{ mx: 0 }}>
                <CardContent> */}
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 300,
                        }}
                        onClick={() => handleViewMedicine(x)}
                      >
                        <LazyLoadImage
                          alt={
                            getSubCatString(x.subCats) +
                            " medicine " +
                            x.generic
                          }
                          effect="blur"
                          src={
                            x.image && x.image.length > 0
                              ? x.image[0]?.src !== "" &&
                                PF +
                                  "adminPanel/medicines/img/" +
                                  x.image[0].src
                              : PF +
                                "adminPanel/medicines/no-medicine-image.jpg"
                          }
                          style={{
                            // width: !smUp ? "89vw" : !mdUp ? "95vw" : "97vw",
                            // height: mdUp ? "500px" : "200px",
                            width: "100%",
                            objectFit: "cover",
                            // borderRadius: "2.5rem",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid
                        container
                        spacing={0}
                        flexDirection="column"
                        justifyContent="center"
                        // alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            color="inherit"
                            sx={{ fontSize: { xs: "1rem", sm: "1.6rem" } }}
                            onClick={() => handleViewMedicine(x)}
                          >
                            {x.genericBrand.toUpperCase()}
                            <sup
                              style={{
                                borderRadius: ".45rem",
                                padding: "0 5px",
                                background: "#2196f3",
                                color: "white",
                                fontSize: ".9rem",
                                position: "relative",
                                top: -10,
                                fontWeight: 600,
                              }}
                            >
                              Rx
                            </sup>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            color="initial"
                            sx={{
                              fontSize: { xs: ".8rem", sm: "1rem" },
                              fontWeight: 100,
                              mr: 1,
                            }}
                          >
                            {translateFront("Generic")}: {x.generic}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={0}>
                            {/* <Grid item>
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{
                                fontSize: { xs: ".8rem", sm: "1rem" },
                                fontWeight: 100,
                                mr: 1,
                              }}
                            >
                              {translateFront("Category")}:
                            </Typography>
                          </Grid> */}
                            {x.subCats &&
                              x.subCats.map(
                                (y, j) =>
                                  getCat(y.subCatId).subCat && (
                                    <Grid item key={j}>
                                      <Typography
                                        variant="body2"
                                        color="initial"
                                        sx={{
                                          fontSize: { xs: ".8rem", sm: "1rem" },
                                          fontWeight: 100,
                                          mr: 1,
                                        }}
                                        component="span"
                                      >
                                        {getCat(y.subCatId).subCat +
                                          (j + 1 < x.subCats.length ? "," : "")}
                                      </Typography>
                                    </Grid>
                                  )
                              )}
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid container spacing={1} alignItems="center">
                            {/* <Grid item>
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{
                                fontSize: { xs: ".8rem", sm: "1rem" },
                                fontWeight: 100,
                              }}
                            >
                              {translateFront("Mfg Company")}:
                            </Typography>
                          </Grid> */}
                            <Grid item flex={1}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                <LazyLoadImage
                                  src={
                                    getCompany(x.companyId).cImage &&
                                    PF +
                                      "/adminPanel/companies/" +
                                      getCompany(x.companyId).cImage
                                  }
                                  alt={
                                    getCompany(x.companyId).cName &&
                                    getCompany(x.companyId).cName
                                  }
                                  effect="blur"
                                  placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                  style={{
                                    height: "35px",
                                    objectFit: "cover",
                                    borderRadius: ".25rem",
                                  }}
                                />
                              </Box>
                              {/* <Button
                              // fullWidth
                              variant="text"
                              size="small"
                              color="inherit"
                              sx={{
                                pointerEvents: "none",
                                fontSize: { xs: ".8rem", sm: "1rem" },
                                fontWeight: 100,
                                ml: -0.6,
                                mt: !smUp && -1.5,
                              }}
                              endIcon={
                                <img
                                  src={
                                    getCompany(x.companyId).cImage &&
                                    PF +
                                      "/adminPanel/companies/" +
                                      getCompany(x.companyId).cImage
                                  }
                                  alt={
                                    getCompany(x.companyId).cName &&
                                    getCompany(x.companyId).cName
                                  }
                                  style={{
                                    height: "25px",
                                    objectFit: "cover",
                                    borderRadius: ".25rem",
                                  }}
                                />
                              }
                            > */}
                              {/* {getCompany(x.companyId).cName} */}
                              {/* </Button> */}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ width: "90%" }}
                            onClick={() => handleViewMedicine(x)}
                          >
                            {translateFront("View")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* </CardContent>
              </Card> */}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      )}
    </>
  );
}
