import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import Favorite from "@mui/icons-material/Favorite";
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  TextField,
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PF } from "../../config";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

// Icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../reduxToolkit/features/frontend/wishlist";
import {
  getMedicines,
  getViewCount,
} from "../../reduxToolkit/features/adminPanel/medicine";
import LoadingButton from "@mui/lab/LoadingButton";
import { promptLogin } from "../../reduxToolkit/features/auth";
import {
  askPrice,
  getAskPriceListByCustomer,
} from "../../reduxToolkit/features/frontend/checkout";
import LoadingBox from "../misc/Loading";
import { getNotificationByCustomer } from "../../reduxToolkit/features/miscFront";
import { getAssignedPriceByCustomer } from "../../reduxToolkit/features/adminPanel/assignPrice";
import { discountCalc } from "../../hooks/discountCalc";
import { currencyFormatter, getDecimal } from "../../hooks/currencyFormatter";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  addToCart,
  getCartInfoByCustomer,
  removeFromCart,
  updateCart,
} from "../../reduxToolkit/features/frontend/cart";

export default function ProductSmall({
  x,
  i,
  view,
  catList,
  lang,
  wishListW,
  setWishOpen,
}) {
  const dispatch = useDispatch();
  const qtyRef = useRef(x && x.variants.map(() => createRef()));
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // use State
  const [companyList, setCompanyList] = useState([]);
  const [dosageList, setDosageList] = useState([]);

  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  // Dialogs
  const [smallAskClicked, setSmallAskClicked] = useState(false);
  const [askPriceDlg, setAskPriceDlg] = useState(false);
  const [askPriceBuffer, setAskPriceBuffer] = useState("");
  const [askPriceVariant, setAskPriceVariant] = useState("");
  const [askPriceLoading, setAskPriceLoading] = useState(false);
  const [askPriceLoadingFromButton, setAskPriceLoadingFromButton] =
    useState(false);
  const [askPriceDisable, setAskPriceDisable] = useState(false);

  const [cartLoading, setCartLoading] = useState(false);
  const [cartBuffer, setCartBuffer] = useState("");
  const [cartRemoveLoading, setCartRemoveLoading] = useState(false);
  const [cartUpdateLoading, setCartUpdateLoading] = useState(false);

  const [addCartDlg, setAddCartDlg] = useState(false);
  const [qty, setQty] = useState([]);
  const [enableUpdateArr, setEnableUpdateArr] = useState([]);

  // const [showAllDosage, setShowAllDosage] = useState([]);

  const [wishListExist, setWishListExist] = useState(false);

  // LocalData
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  // Store
  const { medicines, medVariants, companies, dosages, viewCountArr } =
    useSelector((state) => state.medicine);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { langs } = useSelector((state) => state.misc);
  const { askPriceListByCustomer, askPriceResponse } = useSelector(
    (state) => state.checkout
  );
  const { assignedPriceList } = useSelector((state) => state.assignPrice);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);
  const { carts } = useSelector((state) => state.cart);
  // view Count
  const [viewCount, setViewCount] = useState(0);
  const { webSettings } = useSelector((state) => state.webSettings);

  // const { authData } = useSelector((state) => state.auth);
  // Use Effect
  // useEffect(() => {

  // }, []);
  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);

  // Functions
  const getDefaultImage = () => {
    const I = x.image.find((i) => i.default);
    if (I) return I;
    else return { src: "" };
  };

  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    catList.map((x) => {
      let Sc = x.subCatList.find(
        (sc) =>
          (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
          subCatId
      );
      if (Sc) {
        subCat = Sc.subCatName;
        cat = x.catName;
      }
      return 0;
    });
    return { cat, subCat };
  };

  const getVariant = (variantId) => {
    let variant = {};
    if (medVariants.length > 0) {
      variant = medVariants.find((mv) => mv.fixedVariantId === variantId);
    }
    return variant;
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) => (lang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    const M = medicines.find((m) => m.lang === "en");
    if (M) {
      const med = M.data.find((x) => x.fixedMedicineId === id);
      if (med) genericBrand = med.genericBrand;
    }
    return genericBrand;
  };

  const handleWishlist = () => {
    let obj = {};
    if (lang !== "en") obj.medId = x.medicineDetectorId;
    else obj.medId = x.fixedMedicineId;
    if (localCustomerData() && localCustomerData()._id)
      obj.customerId = localCustomerData()._id;
    if (
      wishlist.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
    )
      dispatch(removeFromWishlist(obj));
    else dispatch(addToWishlist(obj));
  };

  const handleAskPriceDlgClose = () => {
    // setAskPriceBuffer("");
    setAskPriceVariant("");
    setSmallAskClicked(false);
  };
  const getLangId = (langCode) => {
    const L = langs.find((l) => l.langCode === langCode);
    if (L && L._id) return L._id;
    else return 0;
  };

  const handleAskForPrice = (variantId, fromButton) => {
    if (localCustomerData() && localCustomerData()._id) {
      let obj = {
        customerId: localCustomerData()._id,
        medId: lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId,
        medName: x.genericBrand,
        url: makeUrlFriendly(x.genericBrand),
        variantId:
          lang !== "en"
            ? getVariant(variantId).variantDetectorId
            : getVariant(variantId).fixedVariantId,
        langId: getLangId(lang),
        email: localCustomerData().email,
      };
      dispatch(askPrice(obj));
      if (fromButton) {
        setAskPriceLoadingFromButton(true);
      } else {
        setAskPriceLoading(true);
        setAddCartDlg(false);
      }
      setTimeout(() => {
        handleAskPriceDlgClose();
        setAskPriceLoading(false);
        setAskPriceLoadingFromButton(false);
      }, 1000);
    } else {
      dispatch(promptLogin());
    }
  };

  const checkIfAsked = (variantId) => {
    let foundVariant = false;
    foundVariant = askPriceListByCustomer.some(
      (el) =>
        el.variantId ===
        (lang !== "en"
          ? getVariant(variantId).variantDetectorId
          : getVariant(variantId).fixedVariantId)
    );
    return foundVariant;
  };

  const priceExist = () => {
    let varArr = [];
    x.variants.forEach((v) => {
      const V = getVariant(v.variantId);
      if (V) varArr.push(V);
    });
    let counter = 0;
    let obj = {
      exist: false,
      singleExist: false,
      fullExist: false,
    };
    varArr.length > 0 &&
      varArr.forEach((variant) => {
        if (assignedPriceList && assignedPriceList.length > 0) {
          const A = assignedPriceList.find(
            (a) =>
              a.variantId ===
              (lang === "en"
                ? variant.fixedVariantId
                : variant.variantDetectorId)
          );
          if (A) {
            counter++;
          }
        }
        if (
          variant.sellingPrice &&
          variant.sellingPrice > 0 &&
          webSettings.showPrice
        ) {
          counter++;
        }
      });
    if (counter > 0) {
      obj.exist = true;
      if (counter === varArr.length) obj.fullExist = true;
      if (counter < 2 && varArr.length === 1) obj.singleExist = true;
    }
    return obj;
  };

  const priceInfo = (v) => {
    const variant = getVariant(v.variantId);
    let obj = {
      sellingPrice: undefined,
      moq: undefined,
      discount: undefined,
      note: undefined,
      exist: false,
    };
    if (
      variant.sellingPrice &&
      variant.sellingPrice > 0 &&
      webSettings.showPrice
    ) {
      obj.sellingPrice = variant.sellingPrice;
      obj.moq = variant.minimumOrderQty > 1 ? variant.minimumOrderQty : 1;
      obj.discount = variant.discount;
      obj.exist = true;
    }
    if (assignedPriceList && assignedPriceList.length > 0) {
      const A = assignedPriceList.find(
        (a) =>
          a.variantId ===
          (lang === "en" ? variant.fixedVariantId : variant.variantDetectorId)
      );
      if (A) {
        obj.sellingPrice = A.sellingPrice || undefined;
        obj.moq = A.moq || undefined;
        obj.discount = A.discount || undefined;
        obj.note = A.note || undefined;
        obj.exist = true;
      }
    }
    return obj;
  };

  const handleAddQty = (index, cartExist, variantId) => {
    let arr = [...qty];
    arr[index] = Number(qty[index]) + 1;
    setQty(arr);
    if (cartExist) {
      if (!enableUpdateArr.includes(variantId)) {
        let updArr = [...enableUpdateArr];
        updArr.push(variantId);
        setEnableUpdateArr(updArr);
      }
    }
  };

  const handleRemoveQty = (index, minMoq, cartExist, variantId) => {
    let arr = [...qty];
    if (Number(qty[index]) - 1 >= minMoq) arr[index] = Number(qty[index]) - 1;
    setQty(arr);
    if (cartExist) {
      if (!enableUpdateArr.includes(variantId)) {
        let updArr = [...enableUpdateArr];
        // updArr = updArr.filter((u) => u !== variantId);
        updArr.push(variantId);
        setEnableUpdateArr(updArr);
      }
    }
  };

  const handleFixNonEngCart = (variantId) => {
    if (lang !== "en" && medVariants && medVariants.length) {
      const Mv = medVariants.find((mv) => mv.fixedVariantId === variantId);
      if (Mv && Mv.variantDetectorId) variantId = Mv.variantDetectorId;
    }
    return variantId;
  };

  const handleAddToCart = (index, price, minMoq, variantId) => {
    variantId = handleFixNonEngCart(variantId);
    setCartLoading(true);
    setCartBuffer(variantId);
    let qtyArr = [];
    if (qtyRef.current) {
      qtyArr = qtyRef.current.map((ref) =>
        ref.current !== null ? ref.current.value : 0
      );
    } else qtyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let obj = {
      price,
      minMoq,
      moq: Number(!mdUp ? qty[index] : qtyArr[index]),
    };
    if (localCustomerData()._id) obj.customerId = localCustomerData()._id;
    obj.medId = lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId;
    obj.variantId = variantId;
    dispatch(addToCart(obj));
    setTimeout(() => {
      localCustomerData()._id &&
        dispatch(getCartInfoByCustomer(localCustomerData()._id));
      setCartLoading(false);
      setCartBuffer("");
    }, 2000);
  };

  const handleRemoveCart = (variantId) => {
    variantId = handleFixNonEngCart(variantId);
    setCartRemoveLoading(true);
    localCustomerData() &&
      dispatch(
        removeFromCart({ customerId: localCustomerData()._id, variantId })
      );
    setTimeout(() => {
      setCartRemoveLoading(false);
      setCartBuffer("");
    }, 1000);
  };

  const handleUpdateCart = (index, price, minMoq, variantId) => {
    variantId = handleFixNonEngCart(variantId);
    setCartUpdateLoading(true);
    setCartBuffer(variantId);
    let qtyArr = [];
    if (qtyRef.current) {
      qtyArr = qtyRef.current.map((ref) =>
        ref.current !== null ? ref.current.value : 0
      );
    } else qtyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let obj = {
      price,
      minMoq,
      moq: Number(!mdUp ? qty[index] : qtyArr[index]),
    };
    if (localCustomerData()._id) obj.customerId = localCustomerData()._id;
    obj.medId = lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId;
    obj.variantId = variantId;
    localCustomerData()._id && dispatch(updateCart(obj));
    setTimeout(() => {
      // localCustomerData &&
      //   dispatch(getCartInfoByCustomer(localCustomerData._id));
      setEnableUpdateArr([]);
      setCartUpdateLoading(false);
      setCartBuffer("");
    }, 1000);
  };

  const cartExist = (variantId) => {
    let obj = {
      moq: 0,
      exist: false,
    };
    variantId = handleFixNonEngCart(variantId);
    if (carts.length > 0) {
      const C = carts.find((c) => c.variantId === variantId);
      if (C) {
        obj.exist = true;
        obj.moq = C.moq > 1 ? C.moq : 1;
      }
    }
    return obj;
  };

  const cartExistBadge = (variants) => {
    let counter = 0;
    variants.forEach((v) => {
      if (cartExist(v.variantId).exist) counter++;
    });
    return counter > 0 ? false : true;
  };

  const getPriceString = (variants) => {
    let str = "";
    if (variants && variants.length > 0) {
      let arr = [];
      variants.forEach((x) => {
        const variant = getVariant(x.variantId);
        console.log(variant);
        if (variant && variant.sellingPrice && variant.sellingPrice > 0) {
          const priceAfterDiscount = Number(
            variant.sellingPrice - variant.discount
          );
          const price = priceAfterDiscount * exchangeRate.toFixed(2);
          const priceCeil = Math.ceil(price);
          arr.push(
            currencyFormatter(currencyName, Number(priceCeil)).toString()
          );
        }
      });
      if (arr.length > 0) {
        // arr = arr.join(`, ${getSymbolFromCurrency(currencyName)}`);
        if (arr.length > 1) {
          arr =
            arr[0] +
            " - " +
            getSymbolFromCurrency(currencyName) +
            " " +
            arr[arr.length - 1];
        } else arr = arr[0];

        str = `${getSymbolFromCurrency(currencyName)} ${arr}`;
      }
    }
    return str;
  };

  // Get Alt Tag
  const getSubCatString = (subCatArr) => {
    let subCatNameArr = [];

    const arr = [...subCatArr];
    arr.forEach((a) => {
      catList.forEach((x) => {
        let Sc = x.subCatList.find(
          (sc) =>
            (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
            a.subCatId
        );
        if (Sc) {
          subCatNameArr.push(Sc.subCatName);
        }
      });
    });

    return subCatNameArr.join(", ") || "";
  };

  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // if (localCustomerData()._id) {
    //   dispatch(getAssignedPriceByCustomer(localCustomerData()._id));
    //   dispatch(getCartInfoByCustomer(localCustomerData()._id));
    // }
  }, []);

  // Check login status
  // useEffect(() => {
  //   dispatch(getAssignedPriceByCustomer(localCustomerData()._id));
  //   dispatch(getCartInfoByCustomer(localCustomerData()._id));
  //   setTimeout(() => {}, 1000);
  // }, [authData]);

  // Set Quantity mobile & tablet
  useEffect(() => {
    if (x && x.variants) {
      let arr = [];
      let varArr = [];
      x.variants.forEach((v) => {
        const V = getVariant(v.variantId);
        if (V) varArr.push(V);
      });
      varArr.length > 0 &&
        varArr.forEach((variant) => {
          if (assignedPriceList && assignedPriceList.length > 0) {
            const A = assignedPriceList.find(
              (a) =>
                a.variantId ===
                (lang === "en"
                  ? variant.fixedVariantId
                  : variant.variantDetectorId)
            );
            if (A) {
              if (cartExist(A.variantId).exist) {
                arr.push(cartExist(A.variantId).moq);
              } else {
                arr.push(A.moq);
              }
            } else arr.push(1);
          }
        });
      setQty(arr);
    }
  }, [assignedPriceList, addCartDlg, carts, x, lang]);

  // Check if in wishlist
  useEffect(() => {
    if (wishlist && x) {
      wishlist.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
        ? setWishListExist(true)
        : setWishListExist(false);
    }
  }, [wishlist, x]);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, lang]);

  // check For Ask Price
  useEffect(() => {
    if (askPriceListByCustomer && x) {
      const foundMed = askPriceListByCustomer.some(
        (el) =>
          el.medId ===
          (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
      );
      if (foundMed) {
        let counter = 0;
        x.variants.forEach((y) => {
          const foundVariant = askPriceListByCustomer.some(
            (el) =>
              el.variantId ===
              (lang !== "en"
                ? getVariant(y.variantId).variantDetectorId
                : getVariant(y.variantId).fixedVariantId)
          );
          if (foundVariant) counter++;
        });
        if (counter === x.variants.length) {
          setAskPriceDisable(true);
        } else {
          setAskPriceDisable(false);
        }
      } else setAskPriceDisable(false);
    }
  }, [askPriceListByCustomer, lang, x]);

  // check Ask Price Response
  useEffect(() => {
    if (askPriceResponse.message && localCustomerData()) {
      if (!askPriceResponse.failed) {
        if (askPriceBuffer === x._id) {
          setAskPriceDlg(true);
        }
        dispatch(getAskPriceListByCustomer(localCustomerData()._id));
        dispatch(getNotificationByCustomer(localCustomerData()._id));
      }
    }
  }, [
    askPriceBuffer,
    askPriceResponse.failed,
    askPriceResponse.message,
    x._id,
  ]);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  useEffect(() => {
    if (viewCountArr && viewCountArr.length > 0) {
      const C = viewCountArr.find(
        (c) =>
          c.medicineIdEn ===
          (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
      );
      if (C) {
        setViewCount(C.viewCount);
      }
    }
  }, [viewCountArr]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {askPriceLoading ? (
        <LoadingBox w={"100%"} h={"100%"} />
      ) : (
        x && (
          <Box>
            {/* <Zoom in={true} style={{ transitionDelay: `${100 * i + 1}ms` }}> */}
            <Card
              sx={{
                mx: 1,
                maxWidth: wishListW ? "300px" : view === 1 ? "100%" : "100%",
                minWidth: "300px",
                // border: "1px solid black",
              }}
            >
              <Link
                to={`/our-medicines/${makeUrlFriendly(
                  getGenericBrand(
                    lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
                  )
                )}`}
                onClick={() => setWishOpen && setWishOpen(false)}
              >
                {/* {getGenericBrand( */}
                {/* lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId */}
                {/* )} */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: 140,
                  }}
                >
                  <LazyLoadImage
                    src={
                      getDefaultImage() && getDefaultImage().src !== ""
                        ? PF +
                          "adminPanel/medicines/img/" +
                          getDefaultImage().src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={getSubCatString(x.subCats) + " medicine " + x.generic}
                    effect="blur"
                    placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                    style={{
                      objectFit: "stretch",
                      height: view === 0 ? 140 : null,
                      width: view === 0 ? null : "100%",
                    }}
                  />
                </Box>
              </Link>

              <CardContent>
                {/* <Box
                sx={
                  mdUp && {
                    maxHeight: 260,
                    overflow: "auto",
                  }
                }
              > */}
                <Grid
                  container
                  spacing={0}
                  flexDirection="column"
                  // justifyContent="space-between"
                  alignItems="center"
                  // sx={{
                  //   minHeight: view === 0 && 100,
                  // }}
                >
                  <Grid item sx={{ width: "100%" }}>
                    <Typography
                      gutterBottom
                      variant={view === 0 ? "subtitle2" : "h5"}
                      // component="div"
                      align={view === 0 ? "center" : "center"}
                    >
                      {`${x.genericBrand.toUpperCase()} ${
                        view === 0 ? "(" + x.generic.toUpperCase() + ")" : ""
                      }`}
                      {/* <sup
                        style={{
                          borderRadius: ".45rem",
                          padding: "0 5px",
                          background: "#2196f3",
                          color: "white",
                          fontSize: ".9rem",
                          position: "relative",
                          top: -10,
                          fontWeight: 600,
                        }}
                      >
                        Rx
                      </sup> */}
                    </Typography>
                  </Grid>
                  {view === 1 && (
                    <Grid item sx={{ width: "100%" }}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        // component="div"
                        align={view === 0 ? "center" : "center"}
                      >
                        {`${x.generic.toUpperCase()}`}
                      </Typography>
                    </Grid>
                  )}
                  {view === 1 && (
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        sx={{ my: 0.5 }}
                      >
                        {x.variants.map(
                          (y, j) =>
                            getDosageInfo(
                              getVariant(y.variantId).dosageForm
                            ) && (
                              <Grid item key={j}>
                                <Badge
                                  badgeContent={
                                    localCustomerData()._id &&
                                    priceInfo(y).discount &&
                                    priceInfo(y).sellingPrice
                                      ? "- " +
                                        Math.ceil(
                                          discountCalc(
                                            Number(
                                              priceInfo(y)?.sellingPrice || 0
                                            ),
                                            Number(priceInfo(y)?.discount || 0)
                                          )
                                        ) +
                                        "%"
                                      : 0
                                  }
                                  color="info"
                                >
                                  <Button
                                    variant={
                                      priceInfo(y).sellingPrice
                                        ? "outlined"
                                        : "outlined"
                                    }
                                    size="small"
                                    color={
                                      !priceInfo(y).sellingPrice
                                        ? "info"
                                        : "warning"
                                    }
                                    // color="info"
                                    sx={{
                                      pointerEvents: "none",
                                      // p:
                                      //   localCustomerData()._id &&
                                      //   priceInfo(y).discount &&
                                      //   priceInfo(y).sellingPrice
                                      //     ? 1.5
                                      //     : 0.8,
                                    }}
                                    // fullWidth
                                  >
                                    <Typography
                                      variant="body2"
                                      // color="initial"
                                      sx={{ fontSize: ".8rem" }}
                                    >
                                      {getDosageInfo(
                                        getVariant(y.variantId).dosageForm
                                      )}
                                      {" - "}
                                      {getVariant(y.variantId).strength}
                                      {" - "}
                                      {getVariant(y.variantId).packTypes}
                                      <br />
                                      <span style={{ fontWeight: 600 }}>
                                        {localCustomerData()._id &&
                                          priceInfo(y).sellingPrice &&
                                          "" +
                                            getSymbolFromCurrency(
                                              currencyName
                                            ) +
                                            ""}
                                        {localCustomerData()._id &&
                                          priceInfo(y).sellingPrice &&
                                          currencyFormatter(
                                            currencyName,
                                            Math.round(
                                              (Number(
                                                priceInfo(y).sellingPrice
                                              ) -
                                                (Number(
                                                  priceInfo(y).discount
                                                ) || 0)) *
                                                exchangeRate
                                            )
                                          )}
                                      </span>
                                    </Typography>
                                  </Button>
                                </Badge>
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {view === 1 && (
                    <Grid item>
                      {x.subCats.map(
                        (y, j) =>
                          getCat(y.subCatId).subCat && (
                            <Button
                              variant="text"
                              color="secondary"
                              size="small"
                              fullWidth
                              sx={{ pointerEvents: "none" }}
                              key={j}
                            >
                              <Typography
                                variant="body2"
                                color="initial"
                                sx={{ fontSize: ".7rem" }}
                              >
                                {getCat(y.subCatId).subCat +
                                  " ( " +
                                  getCat(y.subCatId).cat +
                                  " )"}
                              </Typography>
                            </Button>
                          )
                      )}
                    </Grid>
                  )}
                  <Grid item sx={{ width: "100%" }}>
                    {webSettings &&
                      webSettings.showOriginator &&
                      x.originator && (
                        <Typography
                          variant="subtitle2"
                          // size="small"
                          color="purple"
                          // fullWidth
                          // sx={{ my: 1, pointerEvents: "none" }}
                          align={view === 0 ? "center" : "center"}
                        >
                          {" "}
                          {x.originator}
                        </Typography>
                      )}
                  </Grid>
                  {view === 1 && (
                    <Grid item>
                      {getCompany(x.companyId) &&
                        getCompany(x.companyId).cName && (
                          <Button
                            fullWidth
                            variant="text"
                            size="small"
                            color="error"
                            sx={{ pointerEvents: "none" }}
                            endIcon={
                              <img
                                src={
                                  getCompany(x.companyId) &&
                                  getCompany(x.companyId).cImage
                                    ? PF +
                                      "/adminPanel/companies/" +
                                      getCompany(x.companyId).cImage
                                    : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                                }
                                alt={
                                  getCompany(x.companyId).cName &&
                                  getCompany(x.companyId).cImage &&
                                  getCompany(x.companyId).cName
                                }
                                style={{
                                  height: "25px",
                                  objectFit: "cover",
                                  borderRadius: ".25rem",
                                }}
                              />
                            }
                          >
                            {getCompany(x.companyId).cName}
                          </Button>
                        )}
                    </Grid>
                  )}

                  <Grid
                    item
                    sx={{
                      width: "100%",
                      marginTop:
                        getPriceString(x.variants) !== "" ? ".5rem" : 0,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      align={view === 0 ? "center" : "center"}
                    >
                      <span
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: 100,
                        }}
                      >
                        {getPriceString(x.variants) === "" ? (
                          <Button
                            variant={view === 0 ? "text" : "outlined"}
                            // fullWidth={view === 0 ? true : false}
                            size="small"
                            color="info"
                            onClick={() => {
                              if (x.variants.length > 0) {
                                setAskPriceBuffer(x._id);
                                let arr = [];
                                x.variants.forEach((v) => {
                                  if (!checkIfAsked(v.variantId))
                                    arr.push(v.variantId);
                                });
                                if (arr.length > 0) {
                                  setAskPriceVariant(arr[0]);
                                }
                                if (x.variants.length > 1) {
                                  setSmallAskClicked(true);
                                } else {
                                  handleAskForPrice(x.variants[0].variantId);
                                }
                              }
                            }}

                            // disabled={x.variants.length < 1 || askPriceDisable}
                          >
                            {translateFront("Ask For Price")}
                          </Button>
                        ) : (
                          getPriceString(x.variants)
                        )}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container spacing={0} justifyContent="flex-end">
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={`Viewed ${
                        viewCount === 0 ? "0" : viewCount
                      }  time${viewCount > 1 ? "'s" : ""}`}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<VisibilityIcon color="primary" />}
                        sx={{ cursor: "default" }}
                      >
                        {viewCount === 0 ? "0" : viewCount}
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        wishListExist
                          ? translateFront("Remove From Wishlist")
                          : translateFront("Add To Wishlist")
                      }
                    >
                      <IconButton size="small" onClick={() => handleWishlist()}>
                        {wishListExist ? (
                          <FavoriteIcon color="error" />
                        ) : (
                          <FavoriteBorderIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    {!priceExist().fullExist && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={translateFront("Ask For Price")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (x.variants.length > 0) {
                              setAskPriceBuffer(x._id);
                              let arr = [];
                              x.variants.forEach((v) => {
                                if (!checkIfAsked(v.variantId))
                                  arr.push(v.variantId);
                              });
                              if (arr.length > 0) {
                                setAskPriceVariant(arr[0]);
                              }
                              if (x.variants.length > 1) {
                                setSmallAskClicked(true);
                              } else {
                                handleAskForPrice(x.variants[0].variantId);
                              }
                            }
                          }}
                          disabled={x.variants.length < 1 || askPriceDisable}
                        >
                          <RequestQuoteIcon
                            color={
                              x.variants.length < 1 || askPriceDisable
                                ? "inherit"
                                : "success"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  {priceExist().exist && (
                    <Grid item>
                      <Tooltip
                        arrow
                        placement="top"
                        title={translateFront("Cart")}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            localCustomerData()._id
                              ? setAddCartDlg(true)
                              : dispatch(promptLogin())
                          }
                        >
                          <Badge
                            badgeContent={localCustomerData()._id ? 1 : 0}
                            color="error"
                            variant="dot"
                            invisible={cartExistBadge(x.variants)}
                          >
                            <AddShoppingCart color="info" />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </CardActions>
            </Card>
            {/* </Zoom> */}
            {smallAskClicked && (
              <Dialog
                open={smallAskClicked}
                onClose={handleAskPriceDlgClose}
                aria-labelledby={"Ask-For-Price-Dialog"}
                fullWidth
                maxWidth={"sm"}
              >
                <DialogTitle sx={{ textAlign: "center" }}>
                  {translateFront("Ask For Price")}
                </DialogTitle>
                <DialogContent sx={{ overflowX: "hidden", mt: 1 }}>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    size="small"
                    // variant="standard"
                    fullWidth
                    color="success"
                  >
                    <InputLabel id="ask-price-variant">
                      {translateFront("Variant")}
                    </InputLabel>
                    <Select
                      autoFocus
                      labelId="ask-price-variant"
                      value={askPriceVariant}
                      label="Variant"
                      onChange={(e) => setAskPriceVariant(e.target.value)}
                    >
                      {x.variants.map((y, j) => (
                        <MenuItem
                          value={y.variantId}
                          key={j}
                          disabled={checkIfAsked(y.variantId)}
                        >
                          {getDosageInfo(getVariant(y.variantId).dosageForm)}{" "}
                          {getVariant(y.variantId).strength}{" "}
                          {getVariant(y.variantId).packTypes}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <LoadingButton
                    size="small"
                    variant="text"
                    color="success"
                    onClick={() => handleAskForPrice(askPriceVariant)}
                    loading={askPriceLoading}
                  >
                    {translateFront("Ask Price")}
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            )}
            {/* Ask Price Dialog */}
            {localCustomerData() && askPriceBuffer === x._id && askPriceDlg && (
              <Dialog
                open={askPriceDlg}
                onClose={() => {
                  setAskPriceDlg(false);
                  setAskPriceBuffer("");
                }}
                aria-labelledby={"asked-price-successfully"}
                fullWidth
                maxWidth={"md"}
              >
                <DialogContent>
                  <Card>
                    <CardContent>
                      <Typography gutterBottom variant="h5" align="center">
                        {translateFront("Price Request Has Been Submitted")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        {translateFront(
                          "Please Wait, You will receive price information in email "
                        )}
                        "
                        <span style={{ color: "navy" }}>
                          {localCustomerData().email}
                        </span>
                        "
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        {translateFront(
                          "You can also keep an eye on notification"
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </DialogContent>
              </Dialog>
            )}
            {/* Add to cart Dialog */}
            {addCartDlg && (
              <Dialog
                open={addCartDlg}
                onClose={() => {
                  setAddCartDlg(false);
                  setEnableUpdateArr([]);
                }}
                fullWidth
                maxWidth={"sm"}
              >
                <DialogContent>
                  {x.variants.map((y, j) => (
                    <>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Grid item xs={priceInfo(y).exist ? 8 : 6} sm={3}>
                            <Typography
                              variant="body2"
                              color="inherit"
                              sx={{
                                fontSize: "1.2rem",
                                textTransform: "uppercase",
                                fontWeight: 100,
                                textDecoration:
                                  !priceInfo(y).exist && "line-through",
                              }}
                            >
                              {getDosageInfo(
                                getVariant(y.variantId).dosageForm
                              )}{" "}
                            </Typography>
                            <Typography
                              variant="body2"
                              color={!priceInfo(y).exist ? "grey" : "black"}
                            >
                              {getVariant(y.variantId).strength} <br />
                            </Typography>
                            <Typography
                              variant="body2"
                              color={!priceInfo(y).exist ? "grey" : "black"}
                            >
                              {getVariant(y.variantId).packTypes}
                            </Typography>
                          </Grid>
                          {priceInfo(y).exist ? (
                            <>
                              <Grid item xs={4} sm={3}>
                                {priceInfo(y).discount &&
                                Number(priceInfo(y)?.discount || 0) > 0 ? (
                                  <Typography variant="body2" color="inherit">
                                    <span
                                      style={{
                                        fontSize: "1.2rem",
                                        fontWeight: 100,
                                      }}
                                    >
                                      {getSymbolFromCurrency(currencyName)}
                                      {currencyFormatter(
                                        currencyName,
                                        Math.round(
                                          (Number(
                                            priceInfo(y)?.sellingPrice || 0
                                          ) -
                                            Number(
                                              priceInfo(y)?.discount || 0
                                            )) *
                                            exchangeRate
                                        )
                                      )}
                                    </span>
                                    <sup
                                      style={{
                                        fontSize: ".6rem",
                                        fontWeight: 100,
                                        position: "relative",
                                        top: -2,
                                      }}
                                    >
                                      {getDecimal(
                                        Number(
                                          priceInfo(y)?.sellingPrice || 0
                                        ) * exchangeRate
                                      )}
                                    </sup>
                                    <br />
                                    <del
                                      style={{
                                        color: "grey",
                                        fontSize: "1rem",
                                        fontWeight: 100,
                                      }}
                                    >
                                      {getSymbolFromCurrency(currencyName)}
                                      {currencyFormatter(
                                        currencyName,
                                        (
                                          Number(
                                            priceInfo(y)?.sellingPrice || 0
                                          ) * exchangeRate
                                        ).toFixed(2)
                                      )}
                                    </del>{" "}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="inherit">
                                    <span
                                      style={{
                                        fontSize: "1.2rem",
                                        fontWeight: 100,
                                      }}
                                    >
                                      {getSymbolFromCurrency(currencyName)}
                                      {currencyFormatter(
                                        currencyName,
                                        Math.round(
                                          Number(
                                            priceInfo(y)?.sellingPrice || 0
                                          ) * exchangeRate
                                        )
                                      )}
                                    </span>
                                    <sup
                                      style={{
                                        fontSize: ".8rem",
                                        fontWeight: 100,
                                        position: "relative",
                                        top: -2,
                                      }}
                                    >
                                      {getDecimal(
                                        Number(
                                          priceInfo(y)?.sellingPrice || 0
                                        ) * exchangeRate
                                      )}
                                    </sup>
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={8} sm={3} md={2}>
                                <Box>
                                  {!mdUp ? (
                                    <>
                                      <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleRemoveQty(
                                                j,
                                                Number(priceInfo(y).moq),
                                                cartExist(y.variantId).exist,
                                                y.variantId
                                              )
                                            }
                                            disabled={
                                              Number(qty[j]) - 1 <
                                              Number(priceInfo(y).moq)
                                            }
                                          >
                                            <RemoveCircleOutlineIcon
                                              color={
                                                Number(qty[j]) - 1 <
                                                Number(priceInfo(y).moq)
                                                  ? "inherit"
                                                  : "error"
                                              }
                                            />
                                          </IconButton>
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{ fontSize: "1rem" }}
                                          >
                                            {qty[j]}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleAddQty(
                                                j,
                                                cartExist(y.variantId).exist,
                                                y.variantId
                                              )
                                            }
                                          >
                                            <AddCircleOutlineIcon color="success" />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ) : cartRemoveLoading ? (
                                    <LoadingBox w={"100%"} />
                                  ) : (
                                    <TextField
                                      label="Qty"
                                      type="number"
                                      variant="standard"
                                      size="small"
                                      inputRef={qtyRef.current[j]}
                                      InputProps={{
                                        inputProps: {
                                          min: Number(priceInfo(y).moq),
                                        },
                                      }}
                                      defaultValue={
                                        cartExist(y.variantId).exist
                                          ? Number(cartExist(y.variantId).moq)
                                          : Number(priceInfo(y).moq)
                                      }
                                      onChange={(e) => {
                                        if (cartExist(y.variantId).exist) {
                                          if (
                                            !enableUpdateArr.includes(
                                              y.variantId
                                            )
                                          ) {
                                            let updArr = [...enableUpdateArr];
                                            updArr.push(y.variantId);
                                            setEnableUpdateArr(updArr);
                                          }
                                        }
                                        let arr = [...qty];
                                        arr[j] = Number(e.target.value);
                                        setQty(arr);
                                      }}
                                      onKeyPress={(e) => {
                                        e.preventDefault();
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      helperText={`${translateFront(
                                        "Minimum Order Qty"
                                      )} ${Number(priceInfo(y).moq)}`}
                                    />
                                  )}
                                </Box>
                              </Grid>
                              <Grid item xs={4} sm={3} md={2}>
                                <Typography variant="body2" color="inherit">
                                  <span
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: 100,
                                    }}
                                  >
                                    {getSymbolFromCurrency(currencyName)}
                                    {currencyFormatter(
                                      currencyName,
                                      Math.round(
                                        Number(
                                          priceInfo(y).sellingPrice -
                                            (priceInfo(y).discount
                                              ? Number(
                                                  priceInfo(y)?.discount || 0
                                                )
                                              : 0)
                                        ) *
                                          exchangeRate *
                                          qty[j]
                                      )
                                    )}
                                  </span>
                                  <sup
                                    style={{
                                      fontSize: ".8rem",
                                      fontWeight: 100,
                                      position: "relative",
                                      top: -2,
                                    }}
                                  >
                                    {getDecimal(
                                      Number(priceInfo(y)?.sellingPrice || 0) *
                                        exchangeRate
                                    )}
                                  </sup>
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                {cartExist(y.variantId).exist ? (
                                  enableUpdateArr.includes(y.variantId) ? (
                                    <LoadingButton
                                      loading={cartUpdateLoading}
                                      variant="outlined"
                                      color="warning"
                                      size="small"
                                      fullWidth
                                      onClick={() =>
                                        handleUpdateCart(
                                          j,
                                          Number(
                                            priceInfo(y).sellingPrice -
                                              Number(
                                                priceInfo(y).discount
                                                  ? priceInfo(y).discount
                                                  : 0
                                              )
                                          ),
                                          Number(priceInfo(y).moq),
                                          y.variantId
                                        )
                                      }
                                    >
                                      {translateFront("Update Cart")}
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton
                                      loading={cartRemoveLoading}
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      fullWidth
                                      onClick={() =>
                                        handleRemoveCart(y.variantId)
                                      }
                                    >
                                      {translateFront("Remove From Cart")}
                                    </LoadingButton>
                                  )
                                ) : (
                                  <LoadingButton
                                    loading={
                                      // cartBuffer.includes(y.variantId) &&
                                      cartLoading
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onClick={() =>
                                      handleAddToCart(
                                        j,
                                        Number(
                                          priceInfo(y)?.sellingPrice || 0
                                        ) -
                                          Number(
                                            priceInfo(y).discount
                                              ? priceInfo(y).discount
                                              : 0
                                          ),
                                        Number(priceInfo(y).moq),
                                        y.variantId
                                      )
                                    }
                                  >
                                    {translateFront("Add To Cart")}
                                  </LoadingButton>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={6} sm={8}>
                              <LoadingButton
                                loading={askPriceLoadingFromButton}
                                size="small"
                                variant="outlined"
                                color="success"
                                fullWidth
                                onClick={() =>
                                  handleAskForPrice(y.variantId, true)
                                }
                                disabled={checkIfAsked(y.variantId)}
                              >
                                {checkIfAsked(y.variantId)
                                  ? translateFront("Price Requested")
                                  : translateFront("Ask Price")}
                              </LoadingButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Divider sx={{ my: 2 }} />
                      </Grid>
                    </>
                  ))}
                </DialogContent>
              </Dialog>
            )}
          </Box>
        )
      )}
    </>
  );
}
