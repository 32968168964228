import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, Keyboard, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import {
  getMedCategories,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";

import LoadingBox from "../misc/Loading";
import ProductSmall from "../product/ProductSmall";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

export default function BestSelling() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  // ! ============== Medicine Section
  const [lang, setLang] = useState("en");
  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [catList, setCatList] = useState([]);

  // store
  const { medicines, categories } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Use Effect
  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // if (!(categories && categories.length > 0)) dispatch(getMedCategories());
  }, []);

  // Set Medicine
  useEffect(() => {
    setMedLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [...M.data].sort((a, b) =>
          a.genericBrand.toLowerCase() > b.genericBrand.toLowerCase() ? 1 : -1
        );
        arr = arr.filter((m) => m.visible === true);
        arr = arr.filter((a) => a.topSeller === true);
        if (arr) arr = arr.slice(0, 10);
        setMedList([...arr]);
      } else {
        setMedList([]);
      }
    }
    if (medicines) {
      setTimeout(() => {
        setMedLoading(false);
      }, 1000);
    }
  }, [medicines, lang]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
    }
  }, [activeLangFront]);
  // ! ============== Medicine Section

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Card sx={{ m: 1 }}>
        <CardContent>
          <Typography
            variant="h4"
            align="center"
            sx={{
              background: webSettings.siteColorTheme || "lightblue",
              fontSize: { xs: "1.8rem", sm: "2rem" },
              mb: 1,
              pb: 1,
            }}
          >
            {translateFront("Best Seller")}{" "}
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={() => navigate("/our-medicines")}
            >
              {translateFront("View All")}
            </Button>
          </Typography>

          {medLoading ? (
            <LoadingBox w={"100%"} h={"600px"} />
          ) : (
            <Swiper
              slidesPerView={!smUp ? 1 : !mdUp ? 2 : !xlUp ? 3 : 4}
              grabCursor
              modules={[Navigation, Scrollbar, Keyboard, Autoplay]}
              autoplay={{ delay: 5000 }}
              loop={{ loop: true }}
              // keyboard={{ enabled: true }}
              spaceBetween={4}
              navigation
              scrollbar
              className="deal"
            >
              {medList.map((x, i) => (
                <SwiperSlide key={i}>
                  <ProductSmall
                    x={x}
                    i={i}
                    view={0}
                    catList={catList}
                    lang={lang}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </CardContent>
      </Card>
    </>
  );
}
